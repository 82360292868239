body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 1.4rem;
}

small {
  font-size: 65%;
}

hr {
  border-top: 1px solid #dddddd;
}

.center {
  text-align: center;
}

.hidden {
  display: none;
}

.text-muted {
  color: #444444;
  font-weight: 400;
}
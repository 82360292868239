.App {
  position: absolute;
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.availability {
  width: 90vw;
  max-width: 680px;
  margin: 2rem 0;
}

.header-hr {
  margin: 1rem 0 2rem 0;
}

.updated-text {
  color: #999999;
}

.updated-hr {
  margin: 1.5rem 0 1rem 0;
}

@media (min-width: 768px) {
  .availability {
    margin: 1rem;
    padding: 2.5rem 2.5rem .5rem 2.5rem ;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }

  .updated-text {
    position: relative;
    top: 4rem;
  }

  .updated-hr {
    display: none;
  }
}

.availability > h1 {
  display: inline;
}

.profile-img {
  display: inline;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.time-box {
  margin: 1.2rem 0;
}

.error-box {
  margin: 5rem 0;
  text-align: center;
  font-size: 1.5rem;
}

.loading-box {
  margin: 10rem 0;
  text-align: center;
  font-size: 1rem;
}

.day-date {
  margin: .5rem 0;
  font-size: 1.1rem;
  font-weight: 600;
  color: #111111;
}

.date {
  color: #a00000;
}

.times {
  margin: .5rem 0;
}

.more-button {
  background-color: #ffffff;
  color: #111111;
  padding: .25rem 1rem;
  margin: .5rem 0;
  border: 1px solid #333333;
  cursor: pointer;
  border-radius: 6px;
}

.more-button:hover, .more-button:active {
  background-color: #111111;
  color: #ffffff;
}